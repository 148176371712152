<div class="d-flex flex-column flex-sm-row align-items-sm-center gap-4">
  <div
    class="date-range-container"
    [attr.data-label]="'FILTERING.REGISTER_DATE' | translate"
  >
    <mat-datepicker-toggle [for]="year">
      <mat-icon svgIcon="icon-date-range"></mat-icon>
    </mat-datepicker-toggle>
    <mat-date-range-input [rangePicker]="year">
      <input
        matStartDate
        [placeholder]="'FORM.START_YEAR' | translate"
        [value]="displayStartDate"
        (click)="year.open()"
        #sartDateInput
      />
      <input
        matEndDate
        [placeholder]="'FORM.END_YEAR'| translate"
        [value]="displayEndDate"
        #endDateInput
      />
    </mat-date-range-input>
    <mat-icon svgIcon="icon-mark" class="icon-mark" (click) = "onResetForm(sartDateInput, endDateInput)"></mat-icon>
        <div class="custom">
            <mat-date-range-picker
          #year
          startView="multi-year"
          (yearSelected)="onYearSelected($event)"
          [calendarHeaderComponent]="customCalendarHeader"
        ></mat-date-range-picker>  
        </div>  
  </div>
</div>
