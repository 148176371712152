import { Component, EventEmitter, Output, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import {provideMomentDateAdapter} from '@angular/material-moment-adapter';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { CustomDatePickerHeaderComponent } from '../custom-date-picker-header/custom-date-picker-header.component';
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-custom-date-picker',
  templateUrl: './custom-date-picker.component.html',
  styleUrls: ['./custom-date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    provideMomentDateAdapter(MY_FORMATS)
  ]
})
export class CustomDatePickerComponent {
  @ViewChild('year') picker: MatDateRangePicker<any>;
  @Output() childEvent = new EventEmitter<any>();
  displayStartDate: Date
  displayEndDate: Date
  startYear: number
  isSelectingStart: boolean = true;
  customCalendarHeader = CustomDatePickerHeaderComponent;

  onYearSelected(selectedYear: Moment) {
    const year = selectedYear.year();
    if(this.isSelectingStart){
      this.startYear = year
      this.displayStartDate = new Date(year, 0, 1);
      this.isSelectingStart = false
      this.picker.close();
      setTimeout(() => {
        this.picker.open();
      }, 500);
    } else{
      this.childEvent.emit({startYear : this.startYear , endYear : year, clearForm: false})
      this.displayEndDate = new Date(year, 11, 31);
      this.isSelectingStart = true;
      this.picker.close()
    }
  } 
  onResetForm(
    startDateInput: HTMLInputElement,
    endDateInput: HTMLInputElement,
  ): void {
    this.childEvent.emit({clearForm: true})
    startDateInput.value = '';
    endDateInput.value = '';;
    this.isSelectingStart = true;
    this.picker.select(null);
  }
}
