import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { CustomDatePickerComponent } from './custom-date-picker.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    CustomDatePickerComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatNativeDateModule,
    MatInputModule,
    MatDatepickerModule,
    TranslateModule,
  ],
  exports: [
    CustomDatePickerComponent
  ]
})
export class CustomDatePickerModule { }