import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomDatePickerHeaderComponent } from './custom-date-picker-header.component';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [
    CustomDatePickerHeaderComponent
  ],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [
    CustomDatePickerHeaderComponent
  ]
})
export class CustomDatePickerHeaderModule { }
